export default [
  {
    path: '/applyTrial',
    name: 'applyTrial',
    // component: () => import('@/views/applyTrial/applyPage.vue'),
    component: () => import('@/views/common/login/applyFor.vue'),
    meta: {
      title: '申请试用'
    }
  }
]