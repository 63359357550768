import Vue from 'vue'
import {
  Button,
  Select,
  Switch,
  Dialog,
  Popover,
  Tooltip,
  Badge,
  Link,
  Tag,
  TabPane,
  Form,
  FormItem,
  Tabs,
  Input,
  Checkbox,
  Menu,
  MenuItem,
  Message,
  MessageBox,
  Loading,
  Cascader,
  Option,
  Image,
  Table,
  TableColumn,  
  Progress,
  Notification 
} from 'element-ui'

Vue.directive('drag', {
  bind: function(el, binding, vnode) {
    el.onmousedown = function(e) {
      // 按下鼠标时记录鼠标位置和元素位置
      var disx = e.clientX - el.offsetLeft;
      var disy = e.clientY - el.offsetTop;

      document.onmousemove = function(e) {
        // 移动鼠标时计算元素新的位置
        var newLeft = e.clientX - disx;
        var newTop = e.clientY - disy;

        // 更新元素位置
        el.style.left = newLeft + 'px';
        el.style.top = newTop + 'px';
      };

      document.onmouseup = function() {
        // 松开鼠标时清除事件
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
  }
})

Vue.use(Option)
Vue.use(Progress)
Vue.use(Cascader)
Vue.use(Button)
Vue.use(Select)
Vue.use(Switch)
Vue.use(Dialog)
Vue.use(Popover)
Vue.use(Tooltip)
Vue.use(Badge)
Vue.use(Link)
Vue.use(Tag)
Vue.use(TabPane)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Tabs)
Vue.use(Input)
Vue.use(Checkbox)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Image)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Loading.directive);
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$notify = Notification;