export default [
  {
    path: '/login',
    name: 'login',
    // component: () => import('@/views/common/login/loginText.vue'),
    component: () => import('@/views/common/login/newLogin.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/loginCenter',
    name: 'loginCenter',
    component: () => import('@/views/common/login/loginCenter.vue'),
    meta: {
      title: '绑定'
    }
  },
  {
    path: '/platformSelect',
    name: 'platformSelect',
    component: () => import('@/views/common/login/platformSelect.vue'),
    meta: {
      title: '选择平台'
    }
  },
  {
    path: '/forget',
    name: 'forget',
    // component: () => import('@/views/common/login/forgetPassword.vue'),
    component: () => import('@/views/common/login/newForgetPassword.vue'),

    
    meta: {
      title: '忘记密码'
    }
  }
]