import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Fingerprint2 from 'fingerprintjs2'; // 引入fingerprintjs2
import "./config/element";
import "./service/apiService/index";
Vue.config.productionTip = false
import axios from "axios";
import "@/assets/iconfont/iconfont.css";
import "@/assets/iconfont/iconfont.js";
import 'element-ui/lib/theme-chalk/index.css';
// 获取内网 公网ip
let axiosNw = {}
let axiosGw = {}
const requestApi = async () => {
  
  axiosGw = await axios.get(process.env.VUE_APP_CHECKURL)
  if (['122.5.50.82\n', '111.198.26.107\n', '221.0.83.69\n', '114.242.4.18\n', '111.198.26.17\n', '114.242.4.112\n'].includes(axiosGw.data) && window.location.host !== 'dev-preview-sso.360-saas.com') {
    axiosNw = await axios.get('https://server.icuican.cn/check')
  }
}
if (window.requestIdleCallback) {
  requestIdleCallback(() => {
     // 选择哪些信息作为浏览器指纹生成的依据
    const options = {
    fonts: {
      extendedJsFonts: true,
      },
      excludes: {
        audio: true,
        userAgent: true,
        enumerateDevices: true,
        touchSupport: true,
        hasLiedOs: true,
        timezone: true
      },
    };
    // 浏览器指纹
    const fingerprint = Fingerprint2.get(options, async (components) => { // 参数只有回调函数或者options为{}时，默认浏览器指纹依据所有配置信息进行生成
      await requestApi()
      components.push({
        key:'nwip',
        value: axiosNw.data //通过接口获取的到ip
      });
      components.push({
        key:'gwip',
        value: axiosGw.data //通过接口获取的到ip
      });
      const values = components.map(component => component.value); // 配置的值的数组
      values.splice(19, 1)
      values.splice(17, 1)
      values.splice(3, 1)
      values.splice(3, 1)
      let userAgent = navigator.userAgent;
      let language = navigator.language;
      values[24] = userAgent
      values[25] = language
      values[26] = screen.width+screen.height
      const murmur = Fingerprint2.x64hash128(values.join(''), 31); // 生成浏览器指纹
      // console.log(components);
      // console.log(values);
      // console.log(murmur);
      localStorage.setItem('browserId', murmur); // 存储浏览器指纹，在项目中用于校验用户身份和埋点
    });
  });
} else {
  setTimeout(() => {
     // 选择哪些信息作为浏览器指纹生成的依据
    const options = {
    fonts: {
      extendedJsFonts: true,
      },
      excludes: {
        audio: true,
        userAgent: true,
        enumerateDevices: true,
        touchSupport: true,
        hasLiedOs: true,
        timezone: true
      },
    };
    // 浏览器指纹
    const fingerprint = Fingerprint2.get(options, async (components) => { // 参数只有回调函数或者options为{}时，默认浏览器指纹依据所有配置信息进行生成
      await requestApi()
      components.push({
        key:'nwip',
        value: axiosNw.data || '' //通过接口获取的到ip
      });
      components.push({
        key:'gwip',
        value: axiosGw.data || '' //通过接口获取的到ip
      });
      const values = components.map(component => component.value); // 配置的值的数组
      values.splice(19, 1)
      values.splice(17, 1)
      values.splice(3, 1)
      values.splice(3, 1)
      let userAgent = navigator.userAgent;
      let language = navigator.language;
      values[24] = userAgent
      values[25] = language
      values[26] = screen.width+screen.height
      const murmur = Fingerprint2.x64hash128(values.join(''), 31); // 生成浏览器指纹
      // console.log(components);
      // console.log(values);
      // console.log(murmur);
      localStorage.setItem('browserId', murmur); // 存储浏览器指纹，在项目中用于校验用户身份和埋点
    });
  }, 500);
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
