/**
 * 书写规则：
 * 基于axios二次封装，接口和方法写一起，页面只需要传入参数即可；
 * 方法名：get请求：getName,post请求：postName；
 */
/**
 * 分销商登录
 */

import {
    HTTP_SDMS
  } from '../axios'

  //  用户列表
export const  postFXlogin= (params) => {
    return HTTP_SDMS.post('/sdms/commission/login', params);
  };
 
