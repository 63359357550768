import axios from "axios";
import {
  HSUC_VUE_APP_AD_URL,
  HSUC_VUE_APP_YAF_URL,
  HSUC_VUE_APP_SDMS_URL,
  HSUC_VUE_APP_ERP_URL,
  HSUC_VUE_APP_ADS_URL,
  HSUC_VUE_APP_AUTO_URL
} from "./hsuc";
import { getType, expandFormData } from "@/utils/utils.js";
const CancelToken = axios.CancelToken;
const pending = new Map();
class HttpRequest {
  constructor(url) {
    this.baseURL = process.env[`VUE_APP_${url}_URL`];
    this.withCredentials = true;
    this.timeout = 10000;
  }
  // 中间件
  middleRequest(options = {}) {
    const instance = axios.create();
    const config = {
      ...options,
      baseURL: this.baseURL,
      timeout: this.timeout,
      withCredentials: this.withCredentials,
    };
    this.setInterceptors(instance, options.url);
    return instance(config);
  }
  // GET
  get(url, params, options = {}) {
    let Authorization = localStorage.getItem('token')?{ Authorization: localStorage.getItem('token') }:{}
    const GET = this.middleRequest({
      method: "get",
      url: url,
      params: params,
      ...options,
      headers: Authorization
    });
    return GET;
  }
  // POST
  post(url, params, options = {}) {
    let Authorization = localStorage.getItem('token')?{ Authorization: localStorage.getItem('token') }:{}
    const POST = this.middleRequest({
      method: "post",
      url: url,
      data: params,
      ...options,
      headers: Authorization
    });
    return POST;
  }
  // 签名
  sign(config) {
    let HSUC_SIGN = null;
    switch (config.baseURL) {
      case process.env.VUE_APP_AD_URL:
        HSUC_SIGN = HSUC_VUE_APP_AD_URL;
        break;
      case process.env.VUE_APP_YAF_URL:
        HSUC_SIGN = HSUC_VUE_APP_YAF_URL;
        break;

        case process.env.VUE_APP_SDMS_URL:
          HSUC_SIGN = HSUC_VUE_APP_SDMS_URL;
          break;
        case process.env.VUE_APP_ERP_URL:
          HSUC_SIGN = HSUC_VUE_APP_ERP_URL;
          break;
          case process.env.VUE_APP_ADS_URL:
            HSUC_SIGN = HSUC_VUE_APP_ADS_URL
            break;
      case process.env.VUE_APP_AUTO_URL:
        HSUC_SIGN = HSUC_VUE_APP_AUTO_URL;
        break;
      default:
        break;
    }
    let data =
      config.method === "get"
        ? config.params
        : getType(config.data) === "FormData"
        ? expandFormData(config.data)
        : config.data;
    if (config.method === "get") {
      config.params = HSUC_SIGN.sign(data, config.method);
    } else {
      config.data = HSUC_SIGN.sign(
        getType(config.data) === "FormData" ? config.data : data,
        config.method
      );
    }
  }
  // 撤销重复请求
  changePending(config) {
    this.sign(config);
    const url = [config.method, config.url].join("&");
    config.cancelToken =
      config.cancelToken ||
      new CancelToken((cancel) => {
        if (pending.has(url)) {
          pending.set(url, cancel);
        } else if (pending.has(url)) {
          const cancel = pending.get(url);
          cancel(url);
          pending.delete(url);
        }
      });
  }
  // 清空清空
  clearPending() {
    for (const [url, cancel] of this.pending) {
      cancel(url);
    }
    this.pending.clear();
  }

  // 拦截器
  setInterceptors(instance) {
    this.requestInterceptors(instance);
    this.responseInterceptors(instance);
  }
  // 请求拦截器
  requestInterceptors(instance) {
    instance.interceptors.request.use(
      (config) => {
        this.changePending(config);
        return config;
      },
      (err) => Promise.reject(err)
    );
  }
  // 响应拦截器
  responseInterceptors(instance) {
    instance.interceptors.response.use(
      (response) => {
        this.changePending(response.config);
        if (response.data.code === 0) {
          return Promise.resolve(response.data);
        } else if (
          [
            105046, 105401, 1190036, 1190037, 1190079, 1190041, 20103, 1190035,
            1190080,
          ].includes(response.data.code)
        ) {
          // 1190080: token失效
          HSUC_VUE_APP_YAF_URL.clearLocalInfo();
          localStorage.removeItem("token");
          window.location.href = window.location.origin + "/login";
          return Promise.reject(response.data);
        } else {
          return Promise.reject(response.data);
        }
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
}

const HTTP_METHODS = new HttpRequest();
const HTTP_YAF = new HttpRequest("YAF");
const HTTP_AD = new HttpRequest("AD");
const HTTP_SDMS = new HttpRequest("SDMS");
const HTTP_ERP = new HttpRequest("ERP");
const HTTP_ADS = new HttpRequest("ADS")
export { HTTP_METHODS, HTTP_YAF, HTTP_AD, HTTP_SDMS,HTTP_ERP,HTTP_ADS };
