/**
 * 书写规则：
 * 基于axios二次封装，接口和方法写一起，页面只需要传入参数即可；
 * 方法名：get请求：getName,post请求：postName；
 */
/**
 * 登录
 */

import { HTTP_ERP } from '../axios';

//  登录
export const serppostLogin = (params) => {
  return HTTP_ERP.post('/user/login/login', params);
}

//  登录获取验证码
export const sendCode = (params) => {
  return HTTP_ERP.post('user/login/sendcode', params);
}

//  退出登录
// export const postLogout = (params) => {
//   return HTTP_ERP.post('login/login/logout', params);
// }

