import { HSUC } from "hsuc";

const HSUC_VUE_APP_AD_URL = new HSUC({
  appCode: 101,
  baseURL: process.env.VUE_APP_AD_URL,
  debug: false,
});

const HSUC_VUE_APP_YAF_URL = new HSUC({
  appCode: process.env.VUE_APP_MODE == 'dev' || process.env.VUE_APP_MODE == 'test' ? 996 : 101,
  baseURL: process.env.VUE_APP_YAF_URL,
  debug: false,
});

const HSUC_VUE_APP_UC_URL = new HSUC({
  appCode: 105,
  baseURL: process.env.VUE_APP_UC_URL,
  debug: false,
});

const HSUC_VUE_APP_SDMS_URL = new HSUC({
  appCode: 142,
  baseURL: process.env.VUE_APP_SDMS_URL,
  debug: false,
  tk: localStorage.getItem('token')
})
const HSUC_VUE_APP_ERP_URL = new HSUC({
  appCode: 128,
  baseURL: process.env.VUE_APP_ERP_URL,
  debug: false
})
const HSUC_VUE_APP_ADS_URL = new HSUC({
  appCode: 120,
  baseURL: process.env.VUE_APP_ADS_URL,
  debug: false
})
const HSUC_VUE_APP_AUTO_URL = new HSUC({
  appCode: 101,
  baseURL: process.env.VUE_APP_AUTO_URL,
  debug: false,
});

export {
  HSUC_VUE_APP_AD_URL,
  HSUC_VUE_APP_YAF_URL,
  HSUC_VUE_APP_UC_URL,
  HSUC_VUE_APP_SDMS_URL,
  HSUC_VUE_APP_ERP_URL,
  HSUC_VUE_APP_ADS_URL,
  HSUC_VUE_APP_AUTO_URL
};
