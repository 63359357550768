/**
 * 书写规则：
 * 基于axios二次封装，接口和方法写一起，页面只需要传入参数即可；
 * 方法名：get请求：getName,post请求：postName；
 */
/**
 * 登录
 */

import { HTTP_YAF } from '../axios'

//  登录
export const postLogin = params => {
  return HTTP_YAF.post('/login/login/login', params)
}
//  获取登录指纹
export const getFingerprint = params => {
  return HTTP_YAF.post('login/login/get_fingerprint_get', params)
}
//  注册
export const postRegister = params => {
  return HTTP_YAF.post('/index/schedule/post', params)
}
// 发送验证码
export const postPhonecaptcha = params => {
  return HTTP_YAF.post('/login/login/phonecaptcha', params)
}
// 新手引导
export const getGuideShow = params => {
  return HTTP_YAF.get('/versions/guide/show', params)
}
// 退出登录
export const postLogout = params => {
  return HTTP_YAF.post('/login/login/logout', params)
}
// 修改密码
export const postChangepwd = params => {
  return HTTP_YAF.post('/usercenter/user/changepwd', params)
}
// 修改密码发送验证码
export const postSendcode = params => {
  return HTTP_YAF.post('/usercenter/user/changepwdsendcode', params)
}
// 试用时间
export const getTryTime = params => {
  return HTTP_YAF.get('/login/try/time', params)
}


// 获取微信开放平台应用的appId
export const getAppId = params => {
  return HTTP_YAF.get('/login/login/getAppId', params)
}


// 微信绑定接口
export const getwechatBind = params => {
  return HTTP_YAF.post('/login/login/wechatBind', params)
}

// 绑定微信发送邮箱验证码
export const getsendEmailCode = params => {
  return HTTP_YAF.post('/login/login/sendEmailCode', params)
}

// 获取用户有权限的平台列表
export const getplatList = params => {
  return HTTP_YAF.get('/login/login/platList', params)
}
// 用户验证重复
export const getverify = params => {
  return HTTP_YAF.post('/usercenter/user/verify', params)
}

