import { HTTP_YAF, HTTP_AD } from '../axios'

// 消息列表
export const postMessageList = (params) =>{
  return HTTP_AD.post('/user/messagelist', params)
}

// 图片裁剪
export const postImgResize = (params) =>{
  return HTTP_AD.post('/personal/updata-picture', params)
}

// 发送邮箱验证码
export const postSendEmail = (params) =>{
  return HTTP_YAF.post('/user/changePwd/email', params)
}

// 修改密码
export const postEditPassword = (params) =>{
  return HTTP_YAF.post('/user/user/updateuserpwd', params)
}

// 消息通道
export const postMessagePipe = (params) =>{
  return HTTP_AD.post('/user/updatestatus', params)
}
// 通过token获取用户信息
export const getUserInfo = params => {
  return HTTP_YAF.get('/usercenter/user/getuserbytoken', params)
}
// 通过imei获取用户信息
export const getUserbyimei = params => {
  return HTTP_YAF.get('/usercenter/user/getuserbyimei', params)
}