import axios from "axios"

// 数据类型判断
const getType = function (data) {
  return Object.prototype.toString.call(data).match(/\s(\w+)/)[1]
}

// 将formdata中的数据转为对象
function expandFormData (form) {
  let data = {}
  for (let [k, v] of form.entries()) {
    if (!['File', 'Undefined'].includes(getType(v))) {
      data[k] = v
    }
  }
  return data
}

// 获取当前服务器各子应用版本资源
async function getVersion (app) {
  let versionInfo = [], baseUrl_main, baseUrl_ocean, baseUrl_qc, res_main_versionInfo, res_ocean_versionInfo, res_qc_versionInfo
  if (process.env.VUE_APP_MODE === 'dev') {
    // name, Entry, PUBLICPATH, CDNBucket, Timestamp
    res_main_versionInfo = { name: 'adsaas-micro', Entry: process.env.VUE_APP_PUBLICPATH,Timestamp:'' }
    res_ocean_versionInfo = { name: 'oceanengine', Entry: process.env.VUE_APP_TOUTIAO_ENTRY,Timestamp:'' }
    res_qc_versionInfo = { name: 'qianchuan', Entry: process.env.VUE_APP_QIANCHUAN_ENTRY,Timestamp:''  }
  }else if(process.env.VUE_APP_MODE === 'test'){
    res_main_versionInfo = { name: 'adsaas-micro', Entry: process.env.VUE_APP_PUBLICPATH.replace(/{origin}/g, window.location.origin),Timestamp:'' }
    res_ocean_versionInfo = { name: 'oceanengine', Entry: process.env.VUE_APP_TOUTIAO_ENTRY.replace(/{origin}/g, window.location.origin),Timestamp:'' }
    res_qc_versionInfo = { name: 'qianchuan', Entry: process.env.VUE_APP_QIANCHUAN_ENTRY.replace(/{origin}/g, window.location.origin),Timestamp:''  }
  } else {
    baseUrl_main = `${window.location.origin}/serverConfig.json`
    baseUrl_ocean = `${window.location.origin}/oceanapp/serverConfig.json`
    baseUrl_qc = `${window.location.origin}/qcapp/serverConfig.json`
    const res1 = await axios.get(baseUrl_main)
    const res2  = await axios.get(baseUrl_ocean)
    const res3  = await axios.get(baseUrl_qc)
    res_main_versionInfo=res1.data
    res_ocean_versionInfo=res2.data
    res_qc_versionInfo=res3.data
  }
  switch (app) {
    case 'oceanapp':
      versionInfo = [res_ocean_versionInfo]
      break;
      case 'qcapp':
        versionInfo = [res_qc_versionInfo]
        break;
    default:
      versionInfo = [res_main_versionInfo, res_ocean_versionInfo, res_qc_versionInfo]
      break;
  }
  return versionInfo

}



export { getType, expandFormData, getVersion }