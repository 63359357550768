/**
 * 书写规则：
 * 基于axios二次封装，接口和方法写一起，页面只需要传入参数即可；
 * 方法名：get请求：getName,post请求：postName；
 */
/**
 * 申请试用
 */

import { HTTP_YAF } from '../axios'
//获取省市县
export const getAreaList = params => {
  return HTTP_YAF.get('/usercenter/area/arealist', params)
}
//公司类型
export const getCompanyType = params => {
  return HTTP_YAF.get('/usercenter/trial/trialcompanytype', params)
}
//申请试用
export const sendTrial = params => {
  return HTTP_YAF.post('/usercenter/trial/trialcreate', params)
}
//媒体类型
export const sendMedialist = params => {
  return HTTP_YAF.get('/usercenter/trial/trialmedia', params)
}
//发送验证码
export const sendcode = params => {
  return HTTP_YAF.post('/usercenter/trial/sendcode', params)
}
