/**
 * 书写规则：
 * 基于axios二次封装，接口和方法写一起，页面只需要传入参数即可；
 * 方法名：get请求：getName,post请求：postName；
 */
/**
 * 登录
 */

import { HTTP_YAF, HTTP_ADS } from '../axios';

//  内部登录
export const cidpostLogin = (params) => {
  return HTTP_YAF.post('/login/login/login', params);
}
//  获取登录指纹
export const cidgetFingerprint = (params) => {
  return HTTP_YAF.post('login/login/get_fingerprint_get', params);
}

//  退出登录
export const cidpostLogout = (params) => {
  return HTTP_YAF.post('login/login/logout', params);
}

/** 获取权限 */
export const cidgetroleauthlist = () => { // 测试135 线上 120
  return HTTP_YAF.post('user/user/getroleauthlist', {platform_id: 120});
};

// 客户登录
export const cidcustomerLogin = (params) => {
  return HTTP_ADS.post('/login/login/login', params);
};
