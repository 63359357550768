import Vue from "vue"
import VueRouter from "vue-router"
import { getPermission } from '@/config/getPermissionList'
import { HTTP_YAF } from '../service/axios.js'
// import actions from "@/utils/actions";
Vue.use(VueRouter);
console.log(process.env.VUE_APP_ADSAAS_ADMIN)
// 动态解析当前路由模块下的文件
const importFile = require.context('@/router', false, /\.js$/)
const lib = importFile.keys()
const fileObj = {}
// 动态导入路由文件
function importAll (r) {
  r.keys().forEach((key) => {
    fileObj[key] = r(key).default
  })
}
importAll(importFile)

// console.log(fileObj)
let routerArry = lib.filter((file, index) => {
  return file !== './index.js'
})
let routeList = []
routerArry.forEach(routerItem => {
  routeList.push(fileObj[routerItem])
})
routeList = routeList.flat().filter(v => v)
const routes = [
  {
    path: '/',
    redirect: '/login',
    component: () => import('../App.vue'),
    children: [
      ...routeList
    ]
  }
];
const router = new VueRouter({  
  mode: "history",
  routes
})
let tokenWhitePathList = ['/index', '/main/oceanapp/creationIndex/materialStorage', '/notPermission', '/login', '/forget', '/applyTrial', '/filePreview','/loginCenter']

const loginSuccess = async (server) => {
  try {
    // ****供uc签名；
    const token = server.access_token;
    localStorage.setItem("token", server.access_token)
    localStorage.setItem("UCSTATE", 1)
    // actions.setGlobalState({ token })
    localStorage.setItem("uid", server.user_id)
    localStorage.setItem("HSUC_LOGIN_INFO", JSON.stringify({ token }))
    let usinfo = {
      company_parent_id: server.company_parent_id,
      auth: server.auth,
      email: server.email,
      headimgurl: server.user_picture,
      initial_pwd: '',
      last_login_time: '',
      nickname: server.user_realname,
      phone: server.phone,
      token: server.access_token,
      type: '',
      uid: server.user_id,
      user_name: server.user_realname,
      auth_scope: server.auth_scope,
      dep_id: server.dept_id,
      user_topsign: server.user_topsign,
      is_bind_wechat:server.is_bind_wechat
    }
    localStorage.setItem('userInfo', JSON.stringify(usinfo))
    // 获取试用时间
    const res = await HTTP_YAF.get('/login/try/time')
    let { try_time_info = {} } = res.data
    localStorage.setItem('tryTime', JSON.stringify(try_time_info))
    // 携带token返回原路径
    let callback_url = sessionStorage.getItem('callback_url')
 

    if (callback_url) {
      window.location.replace(decodeURIComponent(callback_url) + '?token=' + token)
    } else {
      window.location.href = process.env.VUE_APP_ADSAAS_ADMIN
    }
  } catch (error) {
    console.log(error);
  }
}

const loginSuccess1 = async (server) => {
  try {
    // ****供uc签名；
    const token = server.access_token;
    localStorage.setItem("token", server.access_token)
    localStorage.setItem("UCSTATE", 1)
    // actions.setGlobalState({ token })
    localStorage.setItem("uid", server.user_id)
    localStorage.setItem("HSUC_LOGIN_INFO", JSON.stringify({ token }))
    let usinfo = {
      company_parent_id: server.company_parent_id,
      auth: server.auth,
      email: server.email,
      headimgurl: server.user_picture,
      initial_pwd: '',
      last_login_time: '',
      nickname: server.user_realname,
      phone: server.phone,
      token: server.access_token,
      type: '',
      uid: server.user_id,
      user_name: server.user_realname,
      auth_scope: server.auth_scope,
      dep_id: server.dept_id,
      user_topsign: server.user_topsign,
      is_bind_wechat:server.is_bind_wechat
    }
    localStorage.setItem('userInfo', JSON.stringify(usinfo))
    // 获取试用时间
    const res = await HTTP_YAF.get('/login/try/time')
    let { try_time_info = {} } = res.data
    localStorage.setItem('tryTime', JSON.stringify(try_time_info))
    // 携带token返回原路径
    let callback_url = sessionStorage.getItem('callback_url')
 

    if (callback_url) {
      window.location.replace(decodeURIComponent(callback_url) + '?token=' + token)
    } else {
      window.location.href = '/platformSelect'
    }
  } catch (error) {
    console.log(error);
  }
}
// 主应用登录拦截
router.beforeEach(async(to, from, next) => {
  // console.log(to)
  // 来自重定向换取token
// debugger;
  if (to.query.token) {

    try {
      const token=to.query.token
      localStorage.setItem('token', token)
      localStorage.setItem('HSUC_LOGIN_INFO', JSON.stringify({token}))
      HTTP_YAF.get('/user/self/get').then((res) => {
      
        if(to.query.type=='AD-Sass'){
          loginSuccess(res.data)
        }else{
          loginSuccess1(res.data)
        }
      },
      (err) => {
        console.log(err, '获取tokenError')
      }
      )
      // setTimeout(async()=>{llllllllllllll,,,bnbnnvb fvhkfykgykguk
      // await getUserInfo(token)
      return next(process.env.VUE_APP_ADSAAS_ADMIN)
      // })
    } catch (error) {
      console.log(error)
    }
  }
  if(to.name!=='login'&&to.query.callback_url){
  
    const callbackUrl=decodeURIComponent(to.query.callback_url)
    sessionStorage.setItem('callback_url',callbackUrl)
  if(localStorage.getItem('token')){
    try {
      // 判断token是否有效
      await Vue.prototype.$api.getUserInfo({token:localStorage.getItem('token')||''})
      window.location.replace(callbackUrl + '?token='+localStorage.getItem('token'))
    } catch (error) {
      window.location.replace('/login')
    }
    }else{
      window.location.replace('/login')
    }
   
  }
  if(to.path==process.env.VUE_APP_ADSAAS_ADMIN +'/oceanapp/peopleContent'){

    if(to.query.bind_code){
    let resd= await HTTP_YAF.get('/user/self/get')
    let server = resd.data
    let usinfo = {
        company_parent_id: server.company_parent_id,
        auth: server.auth,
        email: server.email,
        headimgurl: server.user_picture,
        initial_pwd: '',
        last_login_time: '',
        nickname: server.user_realname,
        phone: server.phone,
        token: server.access_token,
        type: '',
        uid: server.user_id,
        user_name: server.user_realname,
        auth_scope: server.auth_scope,
        dep_id: server.dept_id,
        user_topsign: server.user_topsign,
        is_bind_wechat:server.is_bind_wechat
      }

    localStorage.setItem('userInfo', JSON.stringify(usinfo))
    }
  }
  if(to.name=='loginCenter'){
    try {
      let browserId = localStorage.getItem("browserId");
      let params = {
        login_type:4,
        unionid:to.query.unionid,
        fingerprint: browserId,
      }
    let res = await  HTTP_YAF.post('/login/login/login',params)
        if(res.code!=422){
            // return next()
            if (res.data.token) {
              try {
                const token=res.data.token
                localStorage.setItem('token', token)
                localStorage.setItem('HSUC_LOGIN_INFO', JSON.stringify({token}))
                HTTP_YAF.get('/user/self/get').then((res) => {
                  loginSuccess1(res.data)
                },
                (err) => {
                  console.log(err, '获取tokenError')
                }
                )
                // setTimeout(async()=>{llllllllllllll,,,bnbnnvb fvhkfykgykguk
                // await getUserInfo(token)
                return next(process.env.VUE_APP_ADSAAS_ADMIN)
                // })
              } catch (error) {
                console.log(error)
              }
            }
        }else{
            return next()
        }
    }catch(err){
      console.log(err)
    }
   
  }
  if (tokenWhitePathList.includes(to.path)) {
        if(to.path=='/index'){
          return next('/login')
        }
    return next()
  }
  getPermission(to, next, 2)

})

router.afterEach((to, from) => {
  sessionStorage.removeItem('LoadingChunk')
})
router.onError((err) => {
})
// 同一路由跳转错误
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}



export default router;
